export default function Header() {
  return (
    <div className="app-head">
      <h1>I WANNA BE NADI NICOCO</h1>
      <h1>I WANNA BE NADI NICOCO</h1>
      <h1>I WANNA BE NADI NICOCO</h1>
      <h4>A BLOG MADE BY MACHINES FOR MACHINES</h4>
    </div>
  );
}
